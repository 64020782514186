import React from "react";
import classNames from "classnames";
import CheckmarkIcon from "@components/svg/CheckmarkIcon";
import "./PricingTable.styles.scss";

const PricingTable = ({ rows, activeCard }) => {
  return (
    <div className="pricing-table container">
      {rows.map(row => {
        return (
          <div className="pricing-table__row">
            <div className="pricing-table__row__title">
              {row.title}
            </div>
            <div className="pricing-table__row__items">
              {row?.items.map(item => {
                return (
                  <div
                    className={classNames(
                      "pricing-table__row__item",
                      {
                        "pricing-table__row__item--active":
                          activeCard === item.type,
                      },
                    )}
                  >
                    {item.hasFeature ? <CheckmarkIcon /> : <div />}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PricingTable;
