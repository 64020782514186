import React, { useState } from "react";
import classNames from "classnames";
import TestimonialBox from "./components/TestimonialBox";
import { Swiper, SwiperSlide } from "swiper/react";

import ArrowIcon from "@components/svg/ArrowIcon";

import "./Testimonials.styles.scss";

const params = {
  slidesPerView: 1,
  speed: 800,
  loop: true,
  spaceBetween: 80,
  centeredSlides: true,
};

const Testimonials = ({ testimonials = [] }) => {
  const [swiper, updateSwiper] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const handleChangeActiveKey = key => {
    swiper && swiper.slideTo(key);
    setActiveStep(key);
  };

  return (
    <section className="testimonials">
      <div className="testimonials__inner container">
        <div className="testimonials__title">
          <div className="title-2">Testimonials</div>
        </div>
        <div className="testimonials__wrapper testimonials__wrapper--desktop">
          {testimonials?.map(item => {
            return <TestimonialBox {...item} />;
          })}
        </div>
        <div className="testimonials__wrapper testimonials__wrapper--mobile">
          <Swiper
            {...params}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={item => {}}
            onSwiper={updateSwiper}
          >
            {testimonials.map(item => {
              return (
                <SwiperSlide>
                  <TestimonialBox {...item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="testimonials__pagination">
          <div
            className={classNames(
              "testimonials__arrow testimonials__arrow--left",
              {
                "testimonials__arrow--disabled": activeStep === 0,
              },
            )}
            onClick={() => {
              handleChangeActiveKey(
                activeStep !== 0 ? activeStep - 1 : activeStep,
              );
            }}
          >
            <ArrowIcon />
          </div>
          {new Array(testimonials.length).fill(0).map((item, key) => {
            return (
              <div
                className={classNames("bullet", {
                  "bullet--active": key === activeStep,
                })}
                onClick={() => handleChangeActiveKey(key)}
              />
            );
          })}
          <div
            className={classNames("testimonials__arrow", {
              "testimonials__arrow--disabled":
                activeStep === testimonials.length,
            })}
            onClick={() => {
              handleChangeActiveKey(
                activeStep !== testimonials.length
                  ? activeStep + 1
                  : activeStep,
              );
            }}
          >
            <ArrowIcon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
