import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

import "./Faq.styles.scss";

const Faq = ({ data }) => {
  return (
    <section className="faq">
      <div className="faq__inner container">
        <div className="faq__title">
          <div className="title-2">Frequently Asked Questions</div>
        </div>
        <div className="faq__questions">
          <Accordion allowZeroExpanded={true}>
            {data.map(({ question, answer }, key) => {
              return (
                <AccordionItem key={key}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      {question}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{answer}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Faq;
