import React from "react";
import Header from "@layouts/Header";
import Pricing from "@sections/Pricing";
import Testimonials from "@sections/Testimonials";
import Faq from "@sections/Faq";

import "./Pricing.styles.scss";

const PricingTemplate = ({ data }) => {
  const {
    header = {},
    prices = {},
    faqData = {},
    testimonials = [],
    cards = [],
    rows = [],
  } = data;
  return (
    <div className="pricing-template">
      <div className="pricing-template__main">
        <Header {...header} />
        <Pricing prices={prices} cards={cards} rows={rows} />
      </div>
      <Testimonials testimonials={testimonials} />
      <Faq data={faqData} />
    </div>
  );
};

export default PricingTemplate;
