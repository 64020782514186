import React from "react";

function CheckmarkIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
      className={className}
    >
      <path
        fill="#D82CD7"
        fillRule="evenodd"
        d="M13.614.21a1 1 0 01.175 1.404l-7.774 10a1 1 0 01-1.477.112l-4.225-4a1 1 0 111.375-1.452l3.424 3.242L12.21.386a1 1 0 011.404-.175z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CheckmarkIcon;
