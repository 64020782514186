import React from "react";
import classNames from "classnames";
import Button from "@components/Button";
import "./PricingCard.styles.scss";

const PricingCard = ({ plan, price, description, isActive }) => {
  return (
    <div
      className={classNames("pricing-card", {
        "pricing-card--active": isActive,
      })}
    >
      <div className="pricing-card__details">
        <div className="pricing-card__plan">{plan}</div>
        <div className="pricing-card__price">${price}</div>
        <div className="pricing-card__description">{description}</div>
      </div>
      <div className="pricing-card__button">
        <Button type={isActive ? "primary" : "white"}>Button</Button>
      </div>
    </div>
  );
};

export default PricingCard;
