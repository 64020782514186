import React from "react";
import PricingTemplate from "@templates/Pricing/Pricing";

import Layout from "@layouts/Layout";

const IndexPage = ({ data: initialData }) => {
  const cmsData = initialData?.markdownRemark?.frontmatter;
  const seo = {
    ...(cmsData?.seo || {}),
    image: cmsData?.seo?.image?.childImageSharp?.fluid?.src,
  };

  const cards = cmsData?.plans.map(item => {
    return {
      ...item,
      planId: item?.title,
      plan: item?.title,
    };
  });

  const prices = {
    yearly: {
      ...cards?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.planId]: { price: parseInt(curr?.yearlyPrice) },
        }),
        {},
      ),
    },
    monthly: {
      ...cards?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.planId]: { price: parseInt(curr?.monthlyPrice) },
        }),
        {},
      ),
    },
  };

  const rows = cmsData?.pricing?.map(item => {
    return {
      ...item,
      items: [
        { type: cards[0]?.planId, hasFeature: item?.medium },
        { type: cards[1]?.planId, hasFeature: item?.advanced },
        { type: cards[2]?.planId, hasFeature: item?.hero },
      ],
    };
  });

  const data = {
    ...(cmsData || {}),
    header: {
      ...(cmsData?.header || {}),
      hasButton: false,
    },
    cards,
    testimonials: cmsData?.testimonialsItems,
    rows,
    prices,
  };

  const navbar = initialData?.navbar?.frontmatter;

  return (
    <Layout navbar={navbar} seo={seo}>
      <PricingTemplate data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PricingPageTemplate {
    navbar: markdownRemark(
      frontmatter: { templateKey: { eq: "NavbarComponent" } }
    ) {
      frontmatter {
        phone
        Items {
          name
          link
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "Pricing" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
        }
        plans {
          title
          description
          monthlyPrice
          yearlyPrice
          isActive
          buttonText
        }
        pricing {
          title
          medium
          advanced
          hero
        }
        faqData {
          question
          answer
        }
        testimonialsItems {
          title
          job
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
