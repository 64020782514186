import React from "react";
import Image from "@components/Image";

import "./TestimonialBox.styles.scss";

const TestimonialBox = ({ title, job, description, image }) => {
  return (
    <div className="testimonial-box">
      <div className="testimonial-box__image">
        <Image data={image} />
      </div>
      <div className="testimonial-box__details">
        <div className="testimonial-box__title">{title}</div>
        <div className="testimonial-box__job">{job}</div>
        <div className="testimonial-box__description">
          {description}
        </div>
      </div>
    </div>
  );
};

export default TestimonialBox;
