import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";

import PricingCard from "./components/PricingCard";
import PricingTable from "./components/PricingTable";

import "./Pricing.styles.scss";

const params = {
  slidesPerView: 3,
  speed: 800,
  loop: false,
  // spaceBetween: 70,
  centeredSlides: true,
  breakpoints: {
    900: {
      centeredSlides: true,
      // centeredSlidesBounds: true,
      spaceBetween: 30,
      slidesPerView: 1,
    },
  },
};

const types = ["medium", "advanced", "hero"];

const Pricing = ({ prices, cards = [], rows = [] }) => {
  const [swiper, updateSwiper] = useState(null);
  const [pricingType, setPricingType] = useState("yearly");
  const [activeCard, setActiveCard] = useState(cards[1]?.planId);

  const pricesByType = prices[pricingType];

  useEffect(() => {
    swiper && swiper.slideTo(1);
  }, [swiper]);

  return (
    <section className="pricing">
      <div className="pricing__inner container">
        <div className="pricing__toggle">
          <div className="pricing__toggle__text">
            <span>Save 25% on Yearly</span>
          </div>
          <div className="pricing__toggle__buttons">
            <div
              className={classNames("pricing-toggle-button", {
                "pricing-toggle-button--active":
                  pricingType === "monthly",
              })}
              onClick={() => setPricingType("monthly")}
            >
              Monthly
            </div>
            <div
              className={classNames("pricing-toggle-button", {
                "pricing-toggle-button--active":
                  pricingType === "yearly",
              })}
              onClick={() => setPricingType("yearly")}
            >
              Yearly
            </div>
          </div>
        </div>
        <div className="pricing__cards pricing__cards--desktop">
          {cards.map(item => {
            return (
              <PricingCard
                plan={item.plan}
                price={pricesByType?.[item.planId]?.price}
                description={item.description}
                isActive={item.isActive}
              />
            );
          })}
        </div>
        <div className="pricing__cards pricing__cards--mobile">
          <Swiper
            {...params}
            onSlideChange={item => {
              setActiveCard(types[item?.realIndex]);
            }}
            onSwiper={updateSwiper}
          >
            {cards.map(item => {
              return (
                <SwiperSlide>
                  <PricingCard
                    plan={item.plan}
                    price={pricesByType?.[item.planId]?.price}
                    description={item.description}
                    isActive={item.isActive}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <PricingTable
        pricingType={pricingType}
        activeCard={activeCard}
        rows={rows}
      />
    </section>
  );
};

export default Pricing;
